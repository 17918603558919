<template>
  <main class="screen default">
    <div class="screen-header">
      <h2 class="screen-title xs:mb-2 mb-0">{{ $t('add_activity.heading') }}</h2>
      <button @click="cancel" class="btn faded">
        {{ $t('add_activity.button_cancel') }}
      </button>
    </div>

    <form id="add-activity" @submit="checkForm" method="post">
      <p class="mb-4">
        <label for="title" class="label">{{ $t('add_activity.title.label') }}</label>
        <input
          id="title"
          v-model="title"
          type="text"
          name="title"
          required
          :placeholder="$t('add_activity.title.placeholder')"
          class="input"
          ref="title"
        />
      </p>

      <p>
        <button type="submit" v-bind:disabled="isSubmitDisabled" class="btn primary">
          {{ $t('add_activity.button_save') }}
        </button>
      </p>
    </form>
  </main>
</template>

<script>
import { Route } from '@/router/index';
import { ADD_ACTIVITY } from '@/store/mutation-types';
import { createId } from '@/utils/id';

export default {
  name: 'AddActivityScreen',
  data() {
    return {
      title: '',
      errors: [],
      suggestion: {},
    };
  },
  computed: {
    isSubmitDisabled() {
      return this.title == '';
    },
  },
  mounted() {
    this.focusInput();
  },
  methods: {
    focusInput() {
      this.$refs.title.focus();
    },

    cancel() {
      this.$router.push({ name: Route.VIEW_ACTIVITIES });
    },

    checkForm(e) {
      e.preventDefault();

      if (!this.title) {
        this.errors = ['Title required.'];
        return;
      }

      this.$store.commit(ADD_ACTIVITY, { id: createId(), title: this.title });
      this.$router.push({ name: Route.VIEW_ACTIVITIES });
    },
  },
};
</script>

<style scoped>
.label {
  @apply font-medium mb-2 block;
}

.input {
  @apply text-lg px-2 py-1 shadow-sm focus:ring-indigo-300 focus:ring outline-none block w-full border border-gray-300 rounded-md;
}
</style>
